import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Box, useTheme, useMediaQuery, Skeleton, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as InfoIconDark } from '../../../assets/icons/info_icon_dark.svg';
import { RootState } from '../../../stores/store';
import { formatCurrency } from '../../../utils/common';
import { labels, messages } from '@utils/translations/en';

interface FundingCalculatorSummaryProps {
    showLoader: boolean;
}

const FundingCalculatorSummary: FC<FundingCalculatorSummaryProps> = ({ showLoader }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const { cashCost, eligibleFund, annualFacilitySize } = fundingCalculatorData.fundingCalculations.summary || {};

    const getConnected = () => {
        const newWindow = window.open('https://pvxpartners.pipedrive.com/scheduler/KkdPWGCK/jeff-cohen-pvx-partners', '_blank');
        if (newWindow) {
            newWindow.focus();
        }
    };

    const getCashCostPercentageRange = () => {
        const start = Math.floor(cashCost * 100);
        const end = Math.ceil(cashCost * 100);
        if (start === end) {
            return `${start.toFixed(0)}%`;
        } else {
            return `${start.toFixed(0)}% - ${end.toFixed(0)}%`;
        }
    };

    const getCashCostRange = () => {
        const start = Math.floor(cashCost * 100);
        const end = Math.ceil(cashCost * 100);
        if (start === end) {
            return `${formatCurrency(start * 10000)}`;
        } else {
            return `${formatCurrency(start * 10000)} - ${formatCurrency(end * 10000)}`;
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: isSmallMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                sx={{
                    width: isMobile ? '100%' : '65%',
                    p: '0.5rem',
                    pl: '1rem',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        flex: '1 1 10rem',
                        py: isMobile ? '0.5rem' : 0,
                        minWidth: isMobile ? 'max-content' : '20%'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: isMobile ? 'center' : 'flex-start' }}>
                        <Typography
                            variant="body2"
                            fontWeight={500}
                            fontSize='0.85rem'
                            color={theme.palette.funding_calculator.main_page.font}
                        >
                            {labels.fundingCalculatorPage.cashCost}
                        </Typography>
                        <Tooltip title={
                            <>
                                <b>{labels.fundingCalculatorPage.cashCost}</b>{messages.fundingCalculatorPage.cashCostTooltip}
                                <br />
                                <br />
                                As an example, if PvX funds $1,000,000 of UA spends, the cash cost would be {getCashCostRange()}.
                            </>
                        }>
                            <IconButton sx={{ px: '0.5rem', pt: '1px', pb: 0 }} >
                                <InfoIconDark style={{ width: '1rem', height: 'auto' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {(cashCost && !showLoader) ? (
                        <Typography variant="h6" color="primary">
                            {getCashCostPercentageRange()}
                        </Typography>
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width="7rem"
                            height="0.8rem"
                            sx={{ borderRadius: '1rem', my: '0.5rem', background: '#00000015' }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        flex: '1 1 10rem',
                        py: isMobile ? '0.5rem' : 0,
                        minWidth: isMobile ? 'max-content' : '50%'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: isMobile ? 'center' : 'flex-start' }}>
                        <Typography
                            variant="body2"
                            fontWeight={500}
                            fontSize='0.85rem'
                            color={theme.palette.funding_calculator.main_page.font}
                        >
                            {labels.fundingCalculatorPage.indicativeFundingAmount}
                        </Typography>
                        <Tooltip title={messages.fundingCalculatorPage.indicativeFundingAmountTooltip}>
                            <IconButton sx={{ px: '0.5rem', pt: '1px', pb: 0 }} >
                                <InfoIconDark style={{ width: '1rem', height: 'auto' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {(eligibleFund && !showLoader) ? (
                        <Typography variant="h6" color="primary">
                            {`${formatCurrency(eligibleFund, true)}/yr`}
                        </Typography>
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width="7rem"
                            height="0.8rem"
                            sx={{ borderRadius: '1rem', my: '0.5rem', background: '#00000015' }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        flex: '1 1 10rem',
                        py: isMobile ? '0.5rem' : 0,
                        minWidth: isMobile ? 'max-content' : '30%'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: isMobile ? 'center' : 'flex-start' }}>
                        <Typography
                            variant="body2"
                            fontWeight={500}
                            fontSize='0.85rem'
                            color={theme.palette.funding_calculator.main_page.font}
                        >
                            {labels.fundingCalculatorPage.monthlyUserAcquisitionSpend}
                        </Typography>
                        <Tooltip title={
                            <>
                                {`Company UA Spends: ${formatCurrency(annualFacilitySize - eligibleFund, true)}`}<br />
                                {`PvX UA Spends: ${formatCurrency(eligibleFund, true)}`}
                            </>
                        }>
                            <IconButton sx={{ px: '0.5rem', pt: '1px', pb: 0 }} >
                                <InfoIconDark style={{ width: '1rem', height: 'auto' }} />
                            </IconButton>
                        </Tooltip>

                    </Box>
                    {(annualFacilitySize && !showLoader) ? (
                        <Typography variant="h6" color="primary">
                            {`${formatCurrency(annualFacilitySize, true)}/yr`}
                        </Typography>
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width="7rem"
                            height="0.8rem"
                            sx={{ borderRadius: '1rem', my: '0.5rem', background: '#00000015' }}
                        />
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    height: 'fit-content',
                    minHeight: '12.5rem',
                    width: isMobile ? '100%' : '35%',
                    minWidth: '12.5rem',
                    backgroundColor: '#E8F4FF',
                    padding: '16px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    fontSize="1.25rem"
                    fontWeight={600}
                    color={theme.palette.funding_calculator.main_page.font}
                    gutterBottom
                >
                    {messages.fundingCalculatorPage.getFundingTerms}
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        background: theme.palette.funding_calculator.main_page.slider_component_background,
                        color: theme.palette.common.white,
                        borderRadius: '2rem',
                        textTransform: 'none',
                        width: '100%',
                        maxWidth: '10rem',
                        px: '1rem',
                    }}
                    onClick={getConnected}
                >
                    {labels.fundingCalculatorPage.getConnected}
                </Button>
            </Box>
        </Box>
    );
};

export default FundingCalculatorSummary;

import { SessionStorage } from '../utils/storage';

const SUPERSET_TOKEN = 'super_auth';

class SupersetAuth {
  static clearToken(): void {
    SessionStorage.clear();
  }

  static setToken(token: string): void {
    SessionStorage.setItem(SUPERSET_TOKEN, token);
  }

  static get token(): string | null {
    return SessionStorage.getItem(SUPERSET_TOKEN);
  }
}

export default SupersetAuth;

import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
  interface Palette {
    custom_background: string;
    custom_field_background: string;
    custom_sidebar: {
      primary: string;
      secondary: string;
      background: string;
      hover: string;
    };
    custom_button: {
      primary: string;
      secondary: string;
      primary_font: string;
      secondary_font: string;
    };
    custom_menu: {
      primary_background: string;
      secondary_background: string;
      selected: string;
      hover: string;
      font: string;
    };
    custom_checkbox: {
      selected: string;
      unselected: string;
    };
    funding_calculator: {
      landing_page: {
        background: string;
        font: string;
        header_background: string;
        calculator_background: string;
        calculator_button: string;
        calculator_slider_value_label_background: string;
        calculator_slider_value_label_font: string;
        calculator_slider_track: string;
        calculator_slider_rail: string;
        calculator_slider_thumb: string;
      },
      main_page: {
        background: string;
        font: string;
        slider_component_background: string;
        recalculate_button: string;
        calculator_slider_value_label_background: string;
        calculator_slider_value_label_font: string;
        calculator_slider_track: string;
        calculator_slider_rail: string;
        calculator_slider_thumb: string;
      }
    }
  }

  interface PaletteOptions {
    custom_background?: string;
    custom_field_background?: string;
    custom_sidebar?: {
      primary?: string;
      secondary?: string;
      background?: string;
      hover?: string;
    };
    custom_button?: {
      primary?: string;
      secondary?: string;
      primary_font?: string;
      secondary_font?: string;
    };
    custom_menu?: {
      primary_background?: string;
      secondary_background?: string;
      selected?: string;
      hover?: string;
      font?: string;
    };
    custom_checkbox?: {
      selected?: string;
      unselected?: string;
    };
    funding_calculator?: {
      landing_page?: {
        background?: string;
        font?: string;
        header_background?: string;
        calculator_background?: string;
        calculator_button?: string;
        calculator_slider_value_label_background?: string;
        calculator_slider_value_label_font?: string;
        calculator_slider_track?: string;
        calculator_slider_rail?: string;
        calculator_slider_thumb?: string;
      },
      main_page?: {
        background?: string;
        font?: string;
        slider_component_background?: string;
        recalculate_button?: string;
        calculator_slider_value_label_background?: string;
        calculator_slider_value_label_font?: string;
        calculator_slider_track?: string;
        calculator_slider_rail?: string;
        calculator_slider_thumb?: string;
      }
    };
  }

  interface Theme {
    sizes: {
      mobile: {
        marginTop: string;
      };
    };
  }

  interface ThemeOptions {
    sizes?: {
      mobile?: {
        marginTop?: string;
      };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#59C1E6",
    },
    secondary: {
      main: "#F5F5F5",
    },
    error: {
      main: '#FF6347',
      light: '#FFD2CC',
      dark: '#800000',
      contrastText: '#FFFFFF',
    },
    custom_sidebar: {
      primary: "#B3E5FC",
      secondary: "#F5F5F5",
      background: "#2C2C34",
      hover: "#11111F",
    },
    custom_button: {
      primary: "#59C1E6",
      secondary: "#F5F5F5",
      primary_font: "#000000",
      secondary_font: "#FFFFFF",
    },
    custom_menu: {
      primary_background: "#FFFFFF",
      secondary_background: "#F5F5F5",
      selected: "#B3E5FC",
      hover: "#E0F7FA",
      font: "#000000",
    },
    custom_checkbox: {
      selected: "#2C2C34",
      unselected: "#2C2C34",
    },
    custom_background: "#F5F5F5",
    custom_field_background: "#ECEFF1",
    funding_calculator: {
      landing_page: {
        background: "#F5F5F5",
        font: "#002F36",
        header_background: "#B3E5FC",
        calculator_background: "#FFFFFF",
        calculator_button: "#59C1E6",
        calculator_slider_value_label_background: "#B3E5FC",
        calculator_slider_value_label_font: "#424242",
        calculator_slider_track: "#59C1E6",
        calculator_slider_rail: "#424242",
        calculator_slider_thumb: "#002F36",
      },
      main_page: {
        background: "#F5F5F5",
        font: "#6B6B6B",
        recalculate_button: "#59C1E6",
        slider_component_background: "#2C2C34",
        calculator_slider_value_label_background: "#B3E5FC",
        calculator_slider_value_label_font: "#11111F",
        calculator_slider_track: "#59C1E6",
        calculator_slider_rail: "#E0E0E0",
        calculator_slider_thumb: "#FFFFFF",
      }
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  sizes: {
    mobile: {
      marginTop: '48px'
    }
  }
});

export default theme;

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery, Drawer, List, ListItem, ListItemText, Typography, Button, IconButton, Box, Divider, Slider, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as InfoIconBlue } from '../../../assets/icons/info_icon_blue.svg';
import {
    PVX_FINANCING_OPTIONS,
    UA_INCREASE_OPTIONS,
    PAYBACK_PERIOD_OPTIONS
} from '../../../constants/constants';
import { DotsLoader } from '../../../components';
import { RootState } from '../../../stores/store';
import { setFundingCalculator } from '../../../stores/FundingCalculator/slices';
import LatestMonthFinancials from './LatestMonthFinancials';
import { FundingCalculatorErrorState } from '../FundingCalculatorPage';
import FundingCalculatorSlider from './FundingCalculatorSlider';
import useDebounce from '../../../hooks/useDebounce';
import { genericTexts, labels, messages, titles } from '@utils/translations/en';

interface FundingCalculatorSideNavProps {
    showLoader: boolean;
    handleRecalculateFunding: () => void;
    errorState: FundingCalculatorErrorState;
    setMobileSidePanel?: (val: boolean) => void;
}

const FundingCalculatorSideNav: FC<FundingCalculatorSideNavProps> = ({
    showLoader,
    handleRecalculateFunding,
    errorState,
    setMobileSidePanel,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const handleUpdateFundingVariables = (value: number | number[], fieldName: string) => {
        const numericValue = fieldName === 'paybackPeriodInMonths' ? (value as number) : (value as number) / 100;
        let fundingCalculationsToUpdate = { ...fundingCalculatorData?.fundingCalculations };
        let fundingCalculationVariables = { ...fundingCalculationsToUpdate?.variables };
        const updatedFundingCalculationVariables = {
            ...fundingCalculationVariables,
            [fieldName]: numericValue
        };

        fundingCalculationsToUpdate.variables = updatedFundingCalculationVariables;

        dispatch(setFundingCalculator(fundingCalculationsToUpdate));
    };

    const handleClickCalculate = () => {
        handleRecalculateFunding();

        if (setMobileSidePanel) {
            setMobileSidePanel(false);
        }
    };

    const debouncedHandleClickCalculate = useDebounce(handleClickCalculate, 800);

    return (
        <Drawer
            id="pvx-funding-calculator-panel"
            variant="permanent"
            sx={{
                height: '100%',
                width: isMobile ? '100%' : '22rem',
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    height: '100%',
                    width: isMobile ? '100%' : '22rem',
                    backgroundColor: '#2E2E2E',
                    color: '#fff',
                    px: '1rem',
                    borderRadius: '1rem',
                },
            }}
        >
            {isMobile && setMobileSidePanel && (
                <Box sx={{ width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                        <CloseIcon sx={{ height: '1.5rem', width: '1.5rem', color: "#e6e0e9", mt: '0.5rem' }} onClick={() => setMobileSidePanel(false)} />
                        <Typography
                            variant="h6"
                            sx={{
                                width: '100%',
                                color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                fontWeight: 600,
                                fontSize: '1rem',
                                textAlign: 'center',
                                my: '0.5rem',
                            }}
                        >
                            {titles.FUNDING_CALCULATOR}
                        </Typography>
                    </Box>
                    <Divider sx={{ background: theme.palette.funding_calculator.main_page.font, height: '1px', width: '100%' }} />
                </Box>
            )}
            <List
                id="pvx-funding-variables-section"
                sx={{
                    height: 'calc(100% - 5rem)',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ListItem
                    id="pvx-pvxFinancing-section"
                    sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    {genericTexts.pvxFunding}
                                </Typography>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 400,
                                        fontSize: '0.7rem'
                                    }}
                                >
                                    {genericTexts.percent}
                                </Typography>
                            </>
                        }
                    />
                    <Tooltip title={messages.fundingCalculatorPage.pvxFundingTooltip}>
                        <IconButton>
                            <InfoIconBlue style={{ width: '0.85rem', height: 'auto', paddingTop: '1px' }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <FundingCalculatorSlider
                    id="pvx-pvxFinancing-slider"
                    value={(fundingCalculatorData?.fundingCalculations?.variables?.pvxFinancing * 100)}
                    onChange={(e, value) => handleUpdateFundingVariables(value, 'pvxFinancing')}
                    formatSelectedValue={(val) => (`${Number(val)}%`)}
                    step={10}
                    defaultOptions={PVX_FINANCING_OPTIONS.map((option, index) => {
                        if (index % 2 === 0) return option;
                        else return { ...option, label: '' }
                    })}
                    error={errorState.pvxFinancing}
                />
                <ListItem
                    id="pvx-monthly-ua-increase-section"
                    sx={{
                        mt: '1rem',
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    {labels.fundingCalculatorPage.monthlyUAIncrease}
                                </Typography>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 400,
                                        fontSize: '0.7rem'
                                    }}
                                >
                                    {genericTexts.percent}
                                </Typography>
                            </>
                        }
                    />
                    <Tooltip title={messages.fundingCalculatorPage.monthlyUAIncreaseTooltip}>
                        <IconButton>
                            <InfoIconBlue style={{ width: '0.85rem', height: 'auto', paddingTop: '1px' }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <FundingCalculatorSlider
                    id="pvx-ua-increase-slider"
                    value={(fundingCalculatorData?.fundingCalculations?.variables.uaIncreasePerMonth * 100)}
                    onChange={(e, value) => handleUpdateFundingVariables(value, 'uaIncreasePerMonth')}
                    formatSelectedValue={(val) => (`${Number(val)}%`)}
                    step={2.5}
                    defaultOptions={UA_INCREASE_OPTIONS}
                    error={errorState.uaIncreasePerMonth}
                />
                <ListItem
                    id="pvx-payback-period-section"
                    sx={{
                        mt: '1rem',
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    {labels.fundingCalculatorPage.paybackPeriod}
                                </Typography>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                                        fontWeight: 400,
                                        fontSize: '0.7rem'
                                    }}
                                >
                                    {genericTexts.inMonths}
                                </Typography>
                            </>
                        }
                    />
                    <Tooltip
                        title={
                            <>
                                {messages.fundingCalculatorPage.paybackPeriodTooltip1}
                                <br />
                                <br />
                                {messages.fundingCalculatorPage.paybackPeriodTooltip2}
                            </>
                        }
                    >
                        <IconButton>
                            <InfoIconBlue style={{ width: '0.85rem', height: 'auto', paddingTop: '1px' }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <Slider
                    value={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths)}
                    onChange={(e, index) => handleUpdateFundingVariables(PAYBACK_PERIOD_OPTIONS[index as number].value, 'paybackPeriodInMonths')}
                    aria-label="Always visible"
                    aria-labelledby="pvx-payback-period-slider"
                    valueLabelFormat={(index: number) => (`<${PAYBACK_PERIOD_OPTIONS[index].value} Months`)}
                    step={null}
                    marks={PAYBACK_PERIOD_OPTIONS.map((opt, index) => ({ value: index, label: `<${opt.label}` }))}
                    min={0}
                    max={PAYBACK_PERIOD_OPTIONS.length - 1}
                    valueLabelDisplay="on"
                    defaultValue={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths)}
                    sx={{
                        width: '80%',
                        my: '1rem',
                        '& .MuiSlider-valueLabel': {
                            backgroundColor: errorState.paybackPeriodInMonths ? theme.palette.error.light : theme.palette.funding_calculator.main_page.calculator_slider_value_label_background,
                            color: theme.palette.funding_calculator.main_page.calculator_slider_value_label_font,
                            fontSize: '0.6rem',
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                        },
                        '& .MuiSlider-markLabel': {
                            color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                            fontSize: '0.7rem',
                        },
                        '& .MuiSlider-track': {
                            height: '0.2rem',
                            color: errorState.paybackPeriodInMonths ? theme.palette.error.light : theme.palette.funding_calculator.main_page.calculator_slider_track,
                        },
                        '& .MuiSlider-rail': {
                            color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                        },
                        '& .MuiSlider-thumb': {
                            height: '1rem',
                            width: '1rem',
                            color: errorState.paybackPeriodInMonths ? theme.palette.error.main : theme.palette.funding_calculator.main_page.calculator_slider_thumb,
                            border: '2px solid',
                            borderColor: errorState.paybackPeriodInMonths ? theme.palette.error.dark : theme.palette.funding_calculator.landing_page.font
                        },
                        '& .MuiSlider-mark': {
                            top: '22px',
                            height: '5px',
                            width: '2px',
                            background: '#E0E0E0'
                        }
                    }}
                />
                <Divider sx={{ background: theme.palette.funding_calculator.main_page.font, height: '1px', width: '90%', mt: '1rem' }} />
                <ListItem
                    id="pvx-latest-month-financials-section"
                    sx={{
                        mt: '1rem',
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={labels.fundingCalculatorPage.lastMonthFinancials}
                        primaryTypographyProps={{
                            variant: 'body1',
                            fontWeight: 600,
                            fontSize: '0.8rem',
                            color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                        }}
                    />
                    <Tooltip title={messages.fundingCalculatorPage.latestMonthFinancialsTooltip}>
                        <IconButton>
                            <InfoIconBlue style={{ width: '0.85rem', height: 'auto', paddingTop: '1px' }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <LatestMonthFinancials errorState={errorState} />
            </List>
            <Divider sx={{ background: theme.palette.funding_calculator.main_page.font, height: '1px', width: '100%', my: '0.5rem' }} />
            <Box
                id="recalculate-funding-data-section"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: 'fit-content',
                    pb: isMobile ? '0.8rem' : 0
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        width: '100%',
                        height: '3rem',
                        display: 'flex',
                        backgroundColor: theme.palette.funding_calculator.main_page.recalculate_button,
                        color: theme.palette.common.white,
                        borderRadius: "2rem",
                        p: 0,
                        m: 0,
                        '&:disabled': {
                            backgroundColor: theme.palette.funding_calculator.main_page.font,
                            color: theme.palette.common.black,
                        },
                    }}
                    onClick={debouncedHandleClickCalculate}
                    disabled={Boolean(Object.values(errorState).some((error) => error))}
                >
                    {showLoader ? (
                        <DotsLoader
                            size={8}
                        />
                    ) : (
                        <Typography
                            sx={{
                                textTransform: "none",
                                fontSize: '1rem',
                                fontWeight: 600,
                                color: theme.palette.common.white,
                            }}
                        >
                            {labels.fundingCalculatorPage.recalculate}
                        </Typography>
                    )}
                </Button>
            </Box>
        </Drawer>
    );
};

export default FundingCalculatorSideNav;


import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, Box, Typography } from '@mui/material';
import { formatCurrency } from '../../../utils/common';
import CurrencyInputField from './CurrencyInputField';
import { FundingCalculatorErrorState } from '../FundingCalculatorPage';
import { RootState } from '../../../stores/store';
import { setFundingCalculator } from '../../../stores/FundingCalculator/slices';
import { CURRENCY_LIMIT_10K, CURRENCY_LIMIT_100M, CURRENCY_LIMIT_100K } from '../../../constants/constants';
import useDebounce from '../../../hooks/useDebounce';
import { genericTexts, labels } from '@utils/translations/en';

type LatestMonthFinancialsPropType = {
    errorState: FundingCalculatorErrorState;
};

const LatestMonthFinancials: FC<LatestMonthFinancialsPropType> = (props) => {
    const { errorState } = props;

    const theme = useTheme();
    const dispatch = useDispatch();
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );
    const [netRevenue, setNetRevenue] = useState<number>(0);
    const [marketingSpends, setMarketingSpends] = useState<number>(0);
    const [latestMonthCashBalance, setLatestMonthCashBalance] = useState<number>(0);
    const [operatingExpenses, setOperatingExpenses] = useState<number>(0);

    // Hook to update states based on redux store values
    useEffect(() => {
        if (fundingCalculatorData?.fundingCalculations?.id) {
            const latestMonthFinancials = fundingCalculatorData.fundingCalculations.variables.latestMonthFinancials;
            setNetRevenue(latestMonthFinancials?.netRevenue);
            setMarketingSpends(latestMonthFinancials.marketingSpends);
            setLatestMonthCashBalance(latestMonthFinancials.latestMonthCashBalance);
            setOperatingExpenses(latestMonthFinancials.operatingExpenses);
        }
    }, [fundingCalculatorData]);

    // Hook to trigger debounced redux store update on state changes
    useEffect(() => {
        updateFundingCalculatorStore();
    }, [netRevenue, marketingSpends, latestMonthCashBalance, operatingExpenses]);

    // Debounced function to update redux store
    const updateFundingCalculatorStore = useDebounce(() => {
        if (fundingCalculatorData?.fundingCalculations?.id) {
            let profit = netRevenue - (marketingSpends + operatingExpenses);

            let fundingCalculationsToUpdate = { ...fundingCalculatorData?.fundingCalculations };
            let fundingCalculationVariablesToUpdate = { ...fundingCalculationsToUpdate?.variables };

            const updatedLatestMonthFinancials = {
                netRevenue,
                marketingSpends,
                latestMonthCashBalance,
                operatingExpenses,
                profitBeforeTaxes: profit,
            };

            fundingCalculationVariablesToUpdate.latestMonthFinancials = updatedLatestMonthFinancials;
            fundingCalculationsToUpdate.variables = fundingCalculationVariablesToUpdate;

            dispatch(setFundingCalculator(fundingCalculationsToUpdate));
        }
    }, 500);

    const handleLatestMonthFinancialsUpdate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFunction: React.Dispatch<React.SetStateAction<number>>) => {
        const inputValue = e.target.value;
        const numericValue = parseFloat(inputValue.replace(/[^0-9]/g, '')) || 0;
        setFunction(numericValue);
    };

    return (
        <Box
            id="latest-month-financials-fields-wrapper"
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Box
                id="net-revenue-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    mt: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.7rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {labels.fundingCalculatorPage.netRevenue}{genericTexts.dollar}
                </Typography>
                <CurrencyInputField
                    label={netRevenue && errorState.netRevenue ? `${formatCurrency(CURRENCY_LIMIT_10K)} - ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    value={netRevenue}
                    onChange={(e) => handleLatestMonthFinancialsUpdate(e, setNetRevenue)}
                    error={errorState.netRevenue}
                />
            </Box>
            <Box
                id="marketing-spends-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    mt: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.7rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {labels.fundingCalculatorPage.marketingSpends}
                </Typography>
                <CurrencyInputField
                    label={marketingSpends && errorState.marketingSpends ? `${formatCurrency(CURRENCY_LIMIT_100K)} - ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    value={marketingSpends}
                    onChange={(e) => handleLatestMonthFinancialsUpdate(e, setMarketingSpends)}
                    error={errorState.marketingSpends}
                />
            </Box>
            <Box
                id="operating-expenses-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    mt: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.7rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {labels.fundingCalculatorPage.operatingExpenses}
                </Typography>
                <CurrencyInputField
                    label={operatingExpenses && errorState.operatingExpenses ? `${formatCurrency(CURRENCY_LIMIT_10K)} - ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    value={operatingExpenses}
                    onChange={(e) => handleLatestMonthFinancialsUpdate(e, setOperatingExpenses)}
                    error={errorState.operatingExpenses}
                />
            </Box>
            <Box
                id="profit-before-taxes-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    mt: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: '0.25rem',
                    border: '1px dashed',
                    borderRadius: '0.5rem'
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {`${formatCurrency(fundingCalculatorData?.fundingCalculations?.variables?.latestMonthFinancials?.profitBeforeTaxes)}`}
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.7rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {labels.fundingCalculatorPage.profit}
                </Typography>
            </Box>
            <Box
                id="latest-month-cash-balance-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    mt: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.9rem',
                        color: theme.palette.funding_calculator.main_page.calculator_slider_rail
                    }}
                >
                    {labels.fundingCalculatorPage.lastMonthCashBalance}
                </Typography>
                <CurrencyInputField
                    label={latestMonthCashBalance && errorState.latestMonthCashBalance ? `${formatCurrency(CURRENCY_LIMIT_10K)} - ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    value={latestMonthCashBalance}
                    onChange={(e) => handleLatestMonthFinancialsUpdate(e, setLatestMonthCashBalance)}
                    error={errorState.latestMonthCashBalance}
                />
            </Box>
        </Box>
    );
}

export default LatestMonthFinancials;

import { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { useAuthenticateMutation } from '../../services/api';
import backgroundImage from '../../assets/images/pvx-home-bg.png';
import UserAuth from '../../services/auth';
import { setAuthToken } from '../../stores/App/slice';
import { genericTexts } from '@utils/translations/en';


const AuthRedirect: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [authenticate] = useAuthenticateMutation();

  const authenticateKeycloak = async (code: string) => {
    try {
      const response = await authenticate({ code }).unwrap();
      const { access_token, refresh_token } = response || {};

      if (access_token && refresh_token) {
        dispatch(setAuthToken({ access_token, refresh_token }))
        UserAuth.setTokens({ access_token, refresh_token });
      }
    } catch (error) {
      console.error('Error while authenticating user :', error);
    }
  }

  // Hook to fetch access token post keycloak authentication
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const codeParam = params.get('code');
    if (codeParam) {
      authenticateKeycloak(codeParam);
      setTimeout(() => {
        navigate(NAV_ROUTES.HOME);
      }, 1000);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#333333CC',
          textAlign: 'center'
        }}
      >
        <div>
          <p>{genericTexts.authenticating}</p>
        </div>
      </Box>
    </>
  );
}

export default AuthRedirect;

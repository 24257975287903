import { Chart } from "chart.js";
import { CHART_TAGS } from "../../constants/constants";
import {
  BarChartUnitValue,
  ChartConfigType,
  ChartDataSet,
  ChartTagType,
  DataPoint,
} from "./types";
import { formatCurrency, percentageDifference } from "../../utils/common";

const legendConfig = {
  display: true,
  position: "bottom",
  align: "start",
  textAlign: "center",
  labels: {
    usePointStyle: true,
    color: "#59C1E6",
    generateLabels: (chart: any) => {
      return chart.data.datasets.map((dataset: any, i: number) => ({
        text: dataset.label || "Dataset " + i,
        fillStyle: dataset.borderColor || dataset.backgroundColor,
        strokeStyle: dataset.borderColor || dataset.backgroundColor,
        lineWidth: 1,
        hidden: !chart.isDatasetVisible(i),
        datasetIndex: i,
      }));
    },
    font: {
      weight: "500",
      size: 12,
    },
  },
};

const findBreakevenMonth = (data: DataPoint[]): number => {
  for (let i = 1; i < data.length; i++) {
    const pointA = data[i - 1];
    const pointB = data[i];

    if (
      (pointA.y <= 100 && pointB.y > 100) ||
      (pointA.y >= 100 && pointB.y < 100)
    ) {
      const slope = (pointB.y - pointA.y) / (pointB.x - pointA.x);
      return pointA.x + (100 - pointA.y) / slope;
    }
  }
  return -Infinity;
};

const drawArrow = (ctx: CanvasRenderingContext2D, from: { x: number, y: number }, to: { x: number, y: number }, arrowLength: number, arrowWidth: number) => {
  const angle = Math.atan2(to.y - from.y, to.x - from.x);

  ctx.beginPath();

  // Move to the end point of the line
  ctx.moveTo(to.x, to.y);

  // Draw first side of the arrow
  ctx.lineTo(
    to.x - arrowLength * Math.cos(angle - Math.PI / 6),
    to.y - arrowLength * Math.sin(angle - Math.PI / 6)
  );

  // Move back to the end of the line
  ctx.moveTo(to.x, to.y);

  // Draw second side of the arrow
  ctx.lineTo(
    to.x - arrowLength * Math.cos(angle + Math.PI / 6),
    to.y - arrowLength * Math.sin(angle + Math.PI / 6)
  );

  ctx.stroke();
}

export const getChartConfig = (
  type: ChartTagType,
  chartDataSet: ChartDataSet,
  metadata?: any
): ChartConfigType | null => {
  switch (type) {
    case CHART_TAGS.CUMULATIVE_ROAS: {
      const breakevenMonth = findBreakevenMonth(chartDataSet[0].data);
      const curvePeak = chartDataSet[0].data.reduce(
        (max, point) => (point.y > max ? point.y : max),
        -Infinity
      );
      const curveLength = chartDataSet[0].data.length;

      const isShowBreakeven = Boolean(
        !isNaN(breakevenMonth) && breakevenMonth > 0 && breakevenMonth < 36
      );

      return {
        plugins: [
          {
            id: "verticalHoverLine",
            afterDatasetsDraw: (chart: Chart) => {
              const {
                ctx,
                chartArea: { top, bottom },
              } = chart;
              ctx.save();
              chart.getDatasetMeta(0).data.forEach((datapoint) => {
                if (datapoint.active) {
                  ctx.beginPath();
                  ctx.strokeStyle = "#000000";
                  ctx.moveTo(datapoint.x, top);
                  ctx.lineTo(datapoint.x, bottom);
                  ctx.stroke();
                }
              });
            },
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index" as "index",
            intersect: false,
          },
          plugins: {
            annotation: {
              annotations: {
                horizontal: {
                  display: isShowBreakeven,
                  type: "line",
                  xMin: 0,
                  yMin: 100,
                  xMax: breakevenMonth - 1 || 0,
                  yMax: 100,
                  borderColor: "#BDBDBD",
                  borderWidth: 2,
                  borderDash: [5, 5],
                  label: {
                    content: "100%",
                    enabled: true,
                    position: "end",
                  },
                },
                vertical: {
                  display: isShowBreakeven,
                  type: "line",
                  xMin: breakevenMonth - 1 || 0,
                  yMin: 0,
                  xMax: breakevenMonth - 1 || 0,
                  yMax: 100,
                  borderColor: "#BDBDBD",
                  borderWidth: 2,
                  borderDash: [5, 5],
                  label: {
                    content: "X-value at 100%",
                    enabled: true,
                    position: "end",
                  },
                },
                outerPoint: {
                  display: isShowBreakeven,
                  type: "point",
                  xValue: breakevenMonth - 1 || 0,
                  yValue: 100,
                  radius: 10,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#59C1E6",
                },
                innerPoint: {
                  display: isShowBreakeven,
                  type: "point",
                  xValue: breakevenMonth - 1 || 0,
                  yValue: 100,
                  radius: 5,
                  backgroundColor: "#59C1E6",
                  borderColor: "#59C1E6",
                },
                customLabel: {
                  display: isShowBreakeven,
                  type: "label",
                  xValue: () => {
                    if (breakevenMonth > curveLength) {
                      return -Infinity;
                    } else {
                      if (breakevenMonth === curveLength) {
                        return breakevenMonth - 1.5;
                      } else {
                        return breakevenMonth - 0.5;
                      }
                    }
                  },
                  yValue: () => {
                    if (curvePeak < 100) {
                      return -Infinity;
                    } else {
                      const unitY = curvePeak / 10;
                      if (unitY > 20) {
                        return 100 + 2 * unitY;
                      } else {
                        return 90;
                      }
                    }
                  },
                  content: ["Payback"],
                  color: "#59C1E6",
                  font: [{ size: 14, weight: "bold" }, { family: "work sans" }],
                },
                text2: {
                  display: isShowBreakeven,
                  type: "label",
                  xValue: () => {
                    if (breakevenMonth > curveLength) {
                      return -Infinity;
                    } else {
                      if (breakevenMonth === curveLength) {
                        return breakevenMonth - 1.5;
                      } else {
                        return breakevenMonth - 0.5;
                      }
                    }
                  },
                  yValue: () => {
                    if (curvePeak < 100) {
                      return -Infinity;
                    } else {
                      const unitY = curvePeak / 10;
                      if (unitY > 20) {
                        return 100 + unitY;
                      } else {
                        return 80;
                      }
                    }
                  },
                  content: [`${breakevenMonth.toFixed(0)} Months`],
                  font: [{ size: 18, weight: "bold" }, { family: "work sans" }],
                },
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: (context: any) => {
                  return `Month ${context[0].label}`;
                },
                label: (context: any) => {
                  const value = Math.round(context.raw);
                  return `${value}% ROAS`;
                },
                labelTextColor: () => {
                  return "#59C1E6";
                },
              },
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretPadding: 10,
              caretSize: 0,
              boxPadding: 4,
              position: "nearest" as "nearest",
              footerColor: "#9E9E9E",
              displayColors: false,
            },
            legend: legendConfig,
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: (value: number) => {
                  return `${value}%`;
                },
                color: "#6B6B6B",
              },
            },
            x: {
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              display: true,
              title: {
                text: 'Elapsed Month',
                display: true,
                color: '#424242'
              }
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        },
      };
    }
    case CHART_TAGS.REVENUE:
      return {
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
            y: {
              stacked: false,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: (value: number) => {
                  return `${formatCurrency(value, true) || 0}`;
                },
                color: "#6B6B6B",
              },
            },
          },
          interaction: {
            mode: "index" as const,
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: (context: any) => {
                  return `Month ${context[0].label}`;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
              },
              yAlign: "bottom" as const,
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretSize: 4,
              boxPadding: 4,
            },
            legend: {
              display: false
            },
          },
        },
      };
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_BALANCE_DELTA:
      return {
        plugins: [
          {
            id: "verticalHoverLine",
            afterDatasetsDraw: (chart: Chart) => {
              const {
                ctx,
                chartArea: { top, bottom },
              } = chart;
              ctx.save();
              chart.getDatasetMeta(0).data.forEach((datapoint) => {
                if (datapoint.active) {
                  ctx.beginPath();
                  ctx.strokeStyle = "#2C2C34";
                  ctx.moveTo(datapoint.x, top);
                  ctx.lineTo(datapoint.x, bottom);
                  ctx.stroke();
                }
              });
            },
          },
          {
            id: "verticalProfitLine",
            afterDatasetsDraw: (chart: Chart) => {
              const { ctx } = chart;
              ctx.restore();
              ctx.save();
              const index = chart.getDatasetMeta(0).data.length - 1;
              const point1 = chart.getDatasetMeta(0).data[index];
              const point2 = chart.getDatasetMeta(1).data[index];
              if (!point1.active || !point2.active) {
                ctx.beginPath();
                ctx.setLineDash([6, 6]);
                ctx.lineWidth = 2;
                ctx.strokeStyle = "#76767A";
                ctx.moveTo(point1.x, point1.y);
                ctx.lineTo(point2.x, point2.y);
                ctx.stroke();
                ctx.save();
                ctx.setLineDash([]);
                drawArrow(ctx, point1, point2, 8, 8);
                drawArrow(ctx, point2, point1, 8, 8);
              }
            },
          },
          {
            id: "profitText",
            afterDatasetDraw: (chart: Chart) => {
              const { ctx } = chart;

              const index = chart.getDatasetMeta(0).data.length - 1;

              const point1 = chart.getDatasetMeta(0).data[index];
              const point2 = chart.getDatasetMeta(1).data[index];

              const investmentBalance = chart.data.datasets[0].data[index] as number;
              const nonInvestmentBalance = chart.data.datasets[1].data[index] as number;

              const balancePercentageDelta = Number(Math.round(percentageDifference(investmentBalance, nonInvestmentBalance))).toFixed(0);

              const textPositionX = point1.x - 40;
              const textPositionY = Math.abs(point1.y + point2.y) / 2;
              ctx.save();

              // Apply font settings
              // ctx.font = "500 18px 'Work Sans'";
              // ctx.fillStyle = "#424242";
              // ctx.textAlign = "center";
              // ctx.fillText(
              //   "Ending Cash Balance",
              //   textPositionX - 50,
              //   textPositionY - 23
              // );

              ctx.font = "bold 20px 'Work Sans'";
              ctx.fillStyle = "#11111F";
              ctx.textAlign = "center";
              ctx.fillText(`${balancePercentageDelta}%`, textPositionX, textPositionY);

              ctx.font = "16px 'Work Sans'";
              ctx.fillStyle = "#424242";
              ctx.textAlign = "center";
              ctx.fillText("Increase", textPositionX, textPositionY + 20);

              ctx.restore();
            },
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index" as "index",
            intersect: false,
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                title: (context: any) => {
                  const title = `Month ${context[0].label}`;
                  return title;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
                footer: (context: any) => {
                  const pointIndex = context[0].dataIndex;
                  const profit =
                    context[0].dataset.data[pointIndex].y -
                    context[1].dataset.data[pointIndex].y;
                  if (profit > 0) return `$${profit} profit`;
                  if (profit < 0) return `$${profit} loss`;
                  return "";
                },
              },
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretPadding: 10,
              caretSize: 0,
              boxPadding: 4,
              position: "nearest" as "nearest",
              footerColor: "#9E9E9E",
            },
            legend: legendConfig,
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: (context: any) =>
                  context.tick.value === 0 ? "#555555" : "transparent",
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: function (value: number) {
                  return `${formatCurrency(Math.abs(value), true) || 0}`;
                },
                color: "#6B6B6B",
              },
            },
            x: {
              border: {
                display: false,
              },
              ticks: {
                color: "#6B6B6B",
              },
              grid: {
                display: false,
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        },
      };
    case CHART_TAGS.PROFIT_BEFORE_TAXES:
      return {
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
            y: {
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: function (value: number) {
                  return `${formatCurrency(Math.abs(value), true)}`;
                },
                color: "#6B6B6B",
              },
            },
          },
          interaction: {
            mode: "index" as const,
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                title: (context: any) => {
                  const title = `Month ${context[0].label}`;
                  return title;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
                footer: (context: any) => {
                  const pointIndex = context[0].dataIndex;
                  const profit =
                    context[0].dataset.data[pointIndex].y -
                    context[1].dataset.data[pointIndex].y;
                  if (profit > 0) return `$${profit} profit`;
                  if (profit < 0) return `$${profit} loss`;
                  return "";
                },
              },
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretPadding: 10,
              caretSize: 0,
              boxPadding: 4,
              position: "nearest" as "nearest",
              footerColor: "#9E9E9E",
            },
            legend: legendConfig,
          },
        },
      };
    case CHART_TAGS.PROFIT_BEFORE_TAXES_CUMULATIVE:
      return {
        plugins: [
          {
            id: "verticalHoverLine",
            afterDatasetsDraw: (chart: Chart) => {
              const {
                ctx,
                chartArea: { top, bottom },
              } = chart;
              ctx.save();
              chart.getDatasetMeta(0).data.forEach((datapoint) => {
                if (datapoint.active) {
                  ctx.beginPath();
                  ctx.strokeStyle = "#2C2C34";
                  ctx.moveTo(datapoint.x, top);
                  ctx.lineTo(datapoint.x, bottom);
                  ctx.stroke();
                }
              });
            },
          },
          {
            id: "verticalProfitLine",
            afterDatasetsDraw: (chart: Chart) => {
              const { ctx } = chart;
              ctx.restore();
              ctx.save();
              const index = chart.getDatasetMeta(0).data.length - 1;
              const point1 = chart.getDatasetMeta(0).data[index];
              const point2 = chart.getDatasetMeta(1).data[index];
              if (!point1.active || !point2.active) {
                ctx.beginPath();
                ctx.setLineDash([6, 6]);
                ctx.lineWidth = 2;
                ctx.strokeStyle = "#76767A";
                ctx.moveTo(point1.x, point1.y);
                ctx.lineTo(point2.x, point2.y);
                ctx.stroke();
                ctx.save();
                ctx.setLineDash([]);
                drawArrow(ctx, point1, point2, 8, 8);
                drawArrow(ctx, point2, point1, 8, 8);
              }
            },
          },
          {
            id: "profitText",
            afterDatasetDraw: (chart: Chart) => {
              const { ctx } = chart;

              const index = chart.getDatasetMeta(0).data.length - 1;

              const point1 = chart.getDatasetMeta(0).data[index];
              const point2 = chart.getDatasetMeta(1).data[index];

              const investmentProfit = chart.data.datasets[0].data[index] as number;
              const nonInvestmentProfit = chart.data.datasets[1].data[index] as number;

              const profitPercentageDelta = Number(Math.round(percentageDifference(investmentProfit, nonInvestmentProfit))).toFixed(0);

              const textPositionX = point1.x - 40;
              const textPositionY = Math.abs(point1.y + point2.y) / 2;
              ctx.save();

              // Apply font settings
              // ctx.font = "500 18px 'Work Sans'";
              // ctx.fillStyle = "#424242";
              // ctx.textAlign = "center";
              // ctx.fillText("Profit", textPositionX, textPositionY - 23);

              ctx.font = "bold 20px 'Work Sans'";
              ctx.fillStyle = "#11111F";
              ctx.textAlign = "center";
              ctx.fillText(
                `${profitPercentageDelta}%`,
                textPositionX,
                textPositionY
              );

              ctx.font = "16px 'Work Sans'";
              ctx.fillStyle = "#424242";
              ctx.textAlign = "center";
              ctx.fillText("Increase", textPositionX, textPositionY + 20);

              ctx.restore();
            },
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index" as "index",
            intersect: false,
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                title: (context: any) => {
                  const title = `Month ${context[0].label}`;
                  return title;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
                footer: (context: any) => {
                  const pointIndex = context[0].dataIndex;
                  const profit =
                    context[0].dataset.data[pointIndex].y -
                    context[1].dataset.data[pointIndex].y;
                  if (profit > 0) return `$${profit} profit`;
                  if (profit < 0) return `$${profit} loss`;
                  return "";
                },
              },
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretPadding: 10,
              caretSize: 0,
              boxPadding: 4,
              position: "nearest" as "nearest",
              footerColor: "#9E9E9E",
            },
            legend: legendConfig,
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: (context: any) =>
                  context.tick.value === 0 ? "#555555" : "transparent",
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: function (value: number) {
                  return `${formatCurrency(Math.abs(value), true) || 0}`;
                },
                color: "#6B6B6B",
              },
            },
            x: {
              border: {
                display: false,
              },
              ticks: {
                color: "#6B6B6B",
              },
              grid: {
                display: false,
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        },
      };
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE:
      return {
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: (value: number) => {
                  return `${formatCurrency(Math.abs(value), true) || 0}`;
                },
                color: "#6B6B6B",
              },
            },
          },
          interaction: {
            mode: "index" as const,
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: (context: any) => {
                  return `Month ${context[0].label}`;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
              },
              yAlign: "bottom" as const,
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretSize: 4,
              boxPadding: 4,
            },
            legend: { ...legendConfig, reverse: "true" },
          },
        },
      };
    case CHART_TAGS.INTEREST_PAID_BAR:
      const { totalInvestmentInterestPaid, totalInvestmentPaybackAmount } =
        metadata;
      return {
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              title: {
                text: 'Month',
                display: true,
                color: '#424242'
              }
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                color: "#2C2C34",
              },
              ticks: {
                callback: (value: number) => {
                  return `${formatCurrency(value, true) || 0}`;
                },
                color: "#6B6B6B",
              },
            },
          },
          interaction: {
            mode: "index" as const,
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: (context: any) => {
                  return `Month ${context[0].label}`;
                },
                label: (context: any) => {
                  const value = context.raw;
                  return `${formatCurrency(value)}`;
                },
                labelTextColor: () => {
                  return "#424242";
                },
              },
              yAlign: "bottom" as const,
              padding: {
                left: 8,
                right: 8,
                top: 8,
                bottom: 8,
              },
              backgroundColor: "#FFFFFF",
              borderColor: "#2C2C34",
              borderWidth: 1,
              titleColor: "#424242",
              titleMarginBottom: 6,
              bodySpacing: 4,
              caretSize: 4,
              boxPadding: 4,
            },
            legend: {
              ...legendConfig,
              labels: {
                usePointStyle: true,
                generateLabels: (chart: any) => {
                  return chart.data.datasets.map((dataset: BarChartUnitValue, i: number) => ({
                    text:
                      `${dataset.label}:  ${i % 2 === 0
                        ? formatCurrency(totalInvestmentPaybackAmount)
                        : formatCurrency(totalInvestmentInterestPaid)
                      }` || "Dataset " + i,
                    fillStyle: chart.data.datasets[i].backgroundColor,
                    strokeStyle: chart.data.datasets[i].backgroundColor,
                    lineWidth: 1,
                    datasetIndex: i,
                  }));
                },
                font: {
                  weight: "500",
                  size: 12,
                },
              },
            },
          },
        },
      };
    case CHART_TAGS.INTEREST_PAID_PIE: {
      const { totalInvestmentInterestPaid, totalInvestmentPaybackAmount } =
        metadata;
      return {
        maintainAspectRatio: true,
        circumference: 180,
        rotation: 270,
        borderWidth: 0,
        plugins: {
          legend: {
            display: true,
            interaction: {
              mode: "index" as "index",
              intersect: false,
            },
            position: "right",
            align: "center",
            labels: {
              usePointStyle: true,
              generateLabels: (chart: any) => {
                return chart.data.labels.map((label: string, i: number) => ({
                  text:
                    `${label}:  $${i % 2 === 0
                      ? formatCurrency(totalInvestmentPaybackAmount)
                      : formatCurrency(totalInvestmentInterestPaid)
                    }` || "Dataset " + i,
                  fillStyle: chart.data.datasets[0].backgroundColor[i],
                  strokeStyle: chart.data.datasets[0].backgroundColor[i],
                  lineWidth: 1,
                  datasetIndex: i,
                }));
              },
              font: {
                weight: "500",
                size: 12,
              },
            },
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return context.label === "Principal Repayment"
                  ? `${formatCurrency(totalInvestmentPaybackAmount)}`
                  : `${formatCurrency(totalInvestmentInterestPaid)}`;
              },
              labelTextColor: () => {
                return "#424242";
              },
            },
            yAlign: "bottom" as const,
            padding: {
              left: 8,
              right: 8,
              top: 8,
              bottom: 8,
            },
            backgroundColor: "#FFFFFF",
            borderColor: "#2C2C34",
            borderWidth: 1,
            titleColor: "#424242",
            titleMarginBottom: 6,
            bodySpacing: 4,
            caretSize: 4,
            boxPadding: 4,
          },
        },
      };
    }
    default:
      return null;
  }
};

import { FC } from 'react';
import { useTheme, Slider } from '@mui/material';

type CurrencyInputFieldPropType = {
    id: string;
    value: number;
    onChange: (e: Event, value: number | number[]) => void;
    defaultOptions: { value: number, label: string }[];
    formatSelectedValue?: (val: string | number) => string;
    step?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    error?: boolean;
};

const FundingCalculatorSlider: FC<CurrencyInputFieldPropType> = (props) => {
    const {
        id,
        value,
        onChange,
        defaultOptions,
        formatSelectedValue,
        step,
        min,
        max,
        error = false,
        disabled = false
    } = props;
    const theme = useTheme();

    return (
        <Slider
            value={value}
            onChange={onChange}
            aria-label="Always visible"
            aria-labelledby={id}
            valueLabelFormat={formatSelectedValue}
            step={step || null}
            marks={defaultOptions}
            min={min || defaultOptions[0].value}
            max={max || defaultOptions[defaultOptions.length - 1].value}
            valueLabelDisplay="on"
            sx={{
                width: '80%',
                my: '1rem',
                '& .MuiSlider-valueLabel': {
                    backgroundColor: error ? theme.palette.error.light : theme.palette.funding_calculator.main_page.calculator_slider_value_label_background,
                    color: theme.palette.funding_calculator.main_page.calculator_slider_value_label_font,
                    fontSize: '0.6rem',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    paddingTop: '3px',
                    paddingBottom: '3px',
                },
                '& .MuiSlider-markLabel': {
                    color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                    fontSize: '0.7rem',
                },
                '& .MuiSlider-track': {
                    height: '0.2rem',
                    color: error ? theme.palette.error.light : theme.palette.funding_calculator.main_page.calculator_slider_track,
                },
                '& .MuiSlider-rail': {
                    color: theme.palette.funding_calculator.main_page.calculator_slider_rail,
                },
                '& .MuiSlider-thumb': {
                    height: '1rem',
                    width: '1rem',
                    color: error ? theme.palette.error.main : theme.palette.funding_calculator.main_page.calculator_slider_thumb,
                    border: '2px solid',
                    borderColor: error ? theme.palette.error.dark : theme.palette.funding_calculator.landing_page.font
                },
                '& .MuiSlider-mark': {
                    top: '22px',
                    height: '5px',
                    width: '2px',
                    background: '#E0E0E0'
                }
            }}
            disabled={disabled}
        />
    );
}

export default FundingCalculatorSlider;

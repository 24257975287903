import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { FullPageLoader } from '../../components';
import { RootState } from '../../stores/store';
import { NAV_ROUTES } from '../../constants/navRoutes';
import emptyDashboard from '../../assets/images/empty-dashboard.svg';
import { getDashboardDataBasedOnUrl } from '../../utils/common';
import { genericTexts, titles } from '@utils/translations/en';
import useGuestToken from '@hooks/api/useGuestToken';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const supersetContainerRef = useRef(null);
  const { module, submodule: subModule } = useParams<{ module?: string; submodule?: string }>();
  const modulesInStore = useSelector((state: RootState) => state?.ModulesData);
  const [isEmptyDashboard, setEmptyDashboard] = useState(false);
  const [dashboardData, setDashboardData] = useState<{ id: string; embedId: string; } | null>(null);
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);

  const { fetchGuestToken: getGuestToken } = useGuestToken();

  // Hook to load embedded dashboard
  useEffect(() => {
    if (dashboardData?.id && dashboardData?.embedId) {
      setDashboardLoader(true)
      getGuestToken(dashboardData.id).then((response) => {
        if (supersetContainerRef.current) {
          embedDashboard({
            id: dashboardData.embedId,
            supersetDomain: `${process.env.REACT_APP_SUPERSET_DASHBOARD_URL}`,
            mountPoint: supersetContainerRef.current,
            fetchGuestToken: () => Promise.resolve(response.token),
            dashboardUiConfig: {
              hideTitle: true,
              filters: {
                expanded: false,
              },
            },
          })
            .then((dashboard: any) => {
              const iframe = (supersetContainerRef.current as unknown as HTMLElement).querySelector('iframe');
              if (iframe) {
                iframe.style.width = '100%';
                iframe.style.height = '100%';
              }

              setTimeout(() => setDashboardLoader(false), 1000);
            })
            .catch((error: any) => console.error('Failed to embed dashboard:', error));
        }
      });
    }
  }, [dashboardData]);

  // Hook to set dashboard redirection
  // if no permission then redirect to home page
  // set dashboard id
  useEffect(() => {
    const redirectModule = modulesInStore?.modules?.[0] || null;
    if (!module && redirectModule && redirectModule?.submodules?.length) {
      setTimeout(() => {
        redirectOnEmptyDashboard();
      }, 1000);
    }

    if (modulesInStore?.loadCount > 1 && !redirectModule) {
      setEmptyDashboard(true);
    }

    if (subModule) {
      const currentDashboardData = getDashboardDataBasedOnUrl(modulesInStore.modules, subModule);
      setDashboardData(currentDashboardData);
    }
  }, [module, modulesInStore, subModule]);

  // Function to redirect to first dashboard in access of user
  const redirectOnEmptyDashboard = () => {
    const redirectModule = modulesInStore?.modules?.[0];
    const redirectSubmodule = redirectModule ? (redirectModule?.submodules?.[0] || null) : null;
    if (redirectModule && redirectSubmodule && !module) {
      const urlModuleParam = redirectModule.name.toLowerCase()
        .replace(/ /g, "_")
        .replace(/\(|\)/g, "");
      navigate(`${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${redirectSubmodule.url}`);
    }
  };

  // If no dashboards present for user display empty dashboard view
  if (isEmptyDashboard) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <img
          src={emptyDashboard}
          alt={titles.PVX_PARTNERS}
          style={{
            width: '150px',
            height: 'auto',
            marginBottom: 20
          }}
        />
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '500',
            marginBottom: 1
          }}
        >
          {genericTexts.noDashboardAccess}
        </Typography>
        <span
          style={{
            fontSize: '0.8rem',
            color: '#6B7280',
            fontWeight: '400',
            maxWidth: '400px'
          }}
        >
          {genericTexts.reachOutAdmin1}
          <a href="mailto:admin@abc.com" style={{ color: '#1D4ED8' }}>
            {genericTexts.administrator}
          </a>
          {genericTexts.reachOutAdmin2}
        </span>
      </Box>
    );
  }

  return (
    <>
      {dashboardLoader && (<FullPageLoader hasSideNav={true} />)}
      <div
        id="pvx-superset-container"
        ref={supersetContainerRef}
        style={{
          width: '100%',
          height: '100vh',
          border: 'none'
        }}
      ></div>
    </>
  );
}

export default Dashboard;

import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

export interface UserSubModulesFilterValueApiMap {
  id: string;
  filterValue: string;
}

export interface UserSubModulesFilterApiMap {
  id: string;
  name: string;
  submoduleFilterRoles: UserSubModulesFilterValueApiMap[];
}

export interface UserSubModulesApiMap {
  id: string;
  name: string;
  url: string;
  embedId: string;
  submoduleFilters: UserSubModulesFilterApiMap[];
}

export interface UserModulesApiMap {
  id: string;
  name: string;
  submoduleCatalogs: UserSubModulesApiMap[];
}

const modules = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getModules: builder.query<any, any>({
      query: () => `${apiRoutes.userModules}`
    }),
    getSubModules: builder.query<any, string>({
      query: (moduleId) => apiRoutes.userSubmodules(moduleId)
    }),
    getSubModuleFilters: builder.query<any, string>({
      query: (submoduleId) => apiRoutes.userSubmoduleFilters(submoduleId)
    }),
    getUserModulesById: builder.query<any, string>({
      query: (id) => apiRoutes.userModulesById(id)
    }),
    getGuestToken: builder.query<any, string>({
      query: (submoduleId) => apiRoutes.userGuestToken(submoduleId)
    }),
  })
});

export const {
  useLazyGetModulesQuery,
  useLazyGetSubModulesQuery,
  useLazyGetSubModuleFiltersQuery,
  useLazyGetUserModulesByIdQuery,
  useLazyGetGuestTokenQuery
} = modules;

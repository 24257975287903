import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useTheme, useMediaQuery, Button, TextField, Typography } from '@mui/material';
import {
    APP_STORE_LINK_REGEX,
    CURRENCY_LIMIT_100K,
    CURRENCY_LIMIT_100M,
    DEFAULT_PAYBACK_PERIOD,
    DEFAULT_PAYBACK_PERIOD_OPTIONS,
    EMAIL_REGEX,
    PLAY_STORE_LINK_REGEX,
    STEAM_STORE_LINK_REGEX
} from '../../constants/constants';
import { CalculatorLayout } from '../../layouts';
import FundingCalculatorHeader from './components/CalculatorHeader';
import FundingCalculatorEmailConfirmation from './components/FundingCalculatorEmailConfirmation';
import { FullPageLoaderWithText } from '../../components';
import { formatCurrency } from '../../utils/common';
import useDebounce from '../../hooks/useDebounce';
import { CreateFundingCalculationPayload } from '../../api/fundingCalculator';
import useCreateFundingCalculation from '../../hooks/api/useCreateFundingCalculation';
import CurrencyInputField from './components/CurrencyInputField';
import { Mixpanel } from '../../services/analytics/mixpanel';
import { labels } from '@utils/translations/en';

const FundingCalculatorLandingPage: FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const { createCalculataion } = useCreateFundingCalculation();

    const [toggleLoader, setLoader] = useState<boolean>(false);
    const [toggleEstimatesView, setEstimatesView] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [gameLink, setGameLink] = useState<string>('');
    const [avgMonthlyMarketingSpend, setAvgMonthlyMarketingSpend] = useState<number | null>(null);
    const [errorState, setErrorState] = useState({ name: false, companyName: false, email: false, gameLink: false, avgMonthlyMarketingSpend: false });
    const [calculationRequestId, setCalculationRequestId] = useState<string>('')

    const isLinkFormatValid = APP_STORE_LINK_REGEX.test(gameLink) || PLAY_STORE_LINK_REGEX.test(gameLink) || STEAM_STORE_LINK_REGEX.test(gameLink);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const utmParam = searchParams.get('utm');

        // Mixpanel Dispatch calculator_landing_page_loaded event
        Mixpanel.track('Load Funding Calculator', {
            utm: utmParam
        });
    }, []);

    // Hook to show loader before estimates view
    useEffect(() => {
        if (calculationRequestId && avgMonthlyMarketingSpend && name) {
            setTimeout(() => {
                setEstimatesView(true);
                setLoader(false);
            }, 2000);
        }
    }, [calculationRequestId, avgMonthlyMarketingSpend, name]);

    const handleGetReport = async () => {
        const errors = {
            ...errorState,
            name: Boolean(!name),
            companyName: Boolean(!companyName),
            email: Boolean(!email || !EMAIL_REGEX.test(email)),
            gameLink: Boolean(!gameLink || !isLinkFormatValid),
            avgMonthlyMarketingSpend: Boolean(!avgMonthlyMarketingSpend || avgMonthlyMarketingSpend < CURRENCY_LIMIT_100K || avgMonthlyMarketingSpend > CURRENCY_LIMIT_100M)
        };
        setErrorState(errors);

        if (!Boolean(Object.values(errors).some((error) => error))) {
            setLoader(true);
            // Mixpanel Dispatch calculate_triggered event
            Mixpanel.track('Calculate Funding', {
                name,
                companyName,
                email,
                gameLink,
                avgMonthlyMarketingSpend
            });
            const data: CreateFundingCalculationPayload = {
                gameUrl: gameLink,
                avgMonthlyMarketingSpend: avgMonthlyMarketingSpend || 0,
                requesterName: name,
                companyName: companyName,
                businessEmail: email,
                paybackPeriod: DEFAULT_PAYBACK_PERIOD,
                cumulativeRoas: DEFAULT_PAYBACK_PERIOD_OPTIONS[DEFAULT_PAYBACK_PERIOD].map((i) => ({ month: i.month, cumulativeRoas: i.value / 100 })),
                suppressNotification: `${process.env.REACT_APP_FUNDING_CALCULATOR_SUPPRESS_NOTIFICATION}` === 'true' ? true : false,
            }
            const res = await createCalculataion(data);
            if (res?.data?.requestId) {
                setCalculationRequestId(res?.data?.requestId)
            };
        }
    }

    const handleDebouncedGetReport = useDebounce(handleGetReport, 800);

    const handleAvgMonthlyMarketingSpend = (value: string) => {
        const amount = parseFloat(value.replace(/[^0-9]/g, ''));
        if (!isNaN(amount)) {
            setAvgMonthlyMarketingSpend(amount);
        } else {
            setAvgMonthlyMarketingSpend(null)
        }
    };

    const getGameLinkLabel = () => {
        if (errorState.gameLink) {
            if (gameLink) {
                return 'Invalid Play Store / App Store link'
            } else {
                return labels.fundingCalculatorLanding.enterPlaystoreAppstore
            }
        } else {
            return labels.fundingCalculatorLanding.enterPlaystoreAppstore
        }
    }

    const getMonthlyMarketingSpendLabel = () => {
        if (errorState.avgMonthlyMarketingSpend) {
            if (avgMonthlyMarketingSpend) {
                return `${formatCurrency(CURRENCY_LIMIT_100K)} - ${formatCurrency(CURRENCY_LIMIT_100M)}`
            } else {
                return labels.fundingCalculatorLanding.enterAverageMarketingSpend
            }
        } else {
            return labels.fundingCalculatorLanding.enterAverageMarketingSpend
        }
    }

    if (toggleEstimatesView && calculationRequestId && avgMonthlyMarketingSpend && name) {
        return (
            <CalculatorLayout>
                <FundingCalculatorEmailConfirmation
                    id={calculationRequestId}
                    monthlyMarketingSpend={avgMonthlyMarketingSpend}
                />
            </CalculatorLayout>
        )
    }

    return (
        <CalculatorLayout>
            {toggleLoader && (
                <FullPageLoaderWithText text="Calculating funding eligibility..." />
            )}
            <Box
                id="calculator_landing_body"
                sx={{
                    height: '100vh',
                    width: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <FundingCalculatorHeader />
                <Box
                    id="calculator_landing_body_card"
                    sx={{
                        width: '100%',
                        maxWidth: '1280px',
                        height: '100%',
                        background: theme.palette.funding_calculator.landing_page.background,
                        color: theme.palette.funding_calculator.landing_page.font,
                        px: isMobile ? '1rem' : '5rem',
                        py: '2rem'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: 'max-content',
                            background: theme.palette.common.white,
                            borderRadius: '1rem',
                            px: '2rem',
                            py: '1rem',
                        }}
                    >
                        <Box
                            id="calculator_landing_body_heading"
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 800,
                                mb: '1rem'
                            }}
                        >
                            Request a cost estimate for your facility
                        </Box>
                        <Box
                            id="calculator_landing_body_form_fields"
                            sx={{
                                width: '100%',
                                height: 'max-content',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '1rem',
                                py: '1rem'
                            }}
                        >
                            <TextField
                                label={getGameLinkLabel()}
                                id="play-store-app-store-link"
                                value={gameLink}
                                onChange={(e) => {
                                    setGameLink(e.target.value);
                                    setErrorState((prev) => ({ ...prev, gameLink: false }));
                                }}
                                sx={{
                                    width: isMobile ? '100%' : 'calc(50% - 1rem)',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    },
                                }}
                                error={errorState.gameLink}
                            />
                            <CurrencyInputField
                                label={getMonthlyMarketingSpendLabel()}
                                id="average-marketing-spend"
                                value={avgMonthlyMarketingSpend || ''}
                                onChange={(e) => {
                                    handleAvgMonthlyMarketingSpend(e.target.value);
                                    setErrorState((prev) => ({ ...prev, avgMonthlyMarketingSpend: false }));
                                }}
                                error={errorState.avgMonthlyMarketingSpend}
                                sx={{
                                    width: isMobile ? '100%' : 'calc(50% - 1rem)',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    },
                                }}
                            />
                            <TextField
                                label="Name"
                                variant="outlined"
                                id="name"
                                sx={{
                                    width: isMobile ? '100%' : 'calc(50% - 1rem)',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    },
                                }}
                                placeholder="Name"
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setErrorState((prev) => ({ ...prev, name: false }));
                                }}
                                error={errorState.name}
                            />
                            <TextField
                                label="Company Name"
                                variant="outlined"
                                id="comapney_name"
                                sx={{
                                    width: isMobile ? '100%' : 'calc(50% - 1rem)',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    },
                                }}
                                placeholder="Company Name"
                                type="text"
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value)
                                    setErrorState((prev) => ({ ...prev, companyName: false }));
                                }}
                                error={errorState.companyName}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                id="email"
                                sx={{
                                    width: isMobile ? '100%' : 'calc(50% - 1rem)',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    },
                                }}
                                placeholder="Email"
                                type="text"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrorState((prev) => ({ ...prev, email: false }));
                                }}
                                error={errorState.email}
                            />
                        </Box>
                        <Typography sx={{ color: "#9E9E9E", fontSize: isMobile ? '0.5rem' : "0.8rem", py: "1rem" }}>
                            By clicking on "Get Estimate", I agree to be contacted by the PvX team
                            and the occasional marketing email. Don't worry, we won't spam and
                            you can unsubscribe at any time. For more information, see
                            our Privacy Policy
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                width: "100%",
                                paddingY: isMobile ? '0.5rem' : "1rem",
                                borderRadius: "10px",
                                textTransform: "none",
                            }}
                            onClick={handleDebouncedGetReport}
                        >
                            Get Estimate
                        </Button>
                        <Typography
                            sx={{
                                width: "100%",
                                color: "#9E9E9E",
                                fontSize: isMobile ? '0.5rem' : "0.7rem",
                                py: '0.5rem'
                            }}
                        >
                            <b>Disclaimer</b><br />The funding amount presented is indicative and subject to change based on PvX’s underwriting process.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </CalculatorLayout>
    );
}

export default FundingCalculatorLandingPage;

import { FC } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import headerImage from '../../../assets/images/calculator-landing.png';
import logoBlack from '../../../assets/images/pvx-partners-black.svg';
import { messages, titles } from '@utils/translations/en';

const CalculatorHeader: FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            id="calculator_landing_header"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'max-content',
                alignItems: 'center',
                background: theme.palette.funding_calculator.landing_page.header_background,
                pt: '0.5rem',
                pr: '8%',
                pb: '2rem',
                pl: '8%',
            }}
        >
            <Box sx={{ maxWidth: '1100px' }}>
                <Box sx={{ pt: '1rem' }}>
                    <img src={logoBlack} alt={titles.PVX_PARTNERS} style={{ width: '8.25rem', height: 'auto' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column-reverse' : 'row',
                        width: '100%',
                        height: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: isMobile ? 'center' : 'flex-start',
                            minWidth: isMobile ? '100%' : '50%',
                            mb: isMobile ? '1.5rem' : '0',
                        }}
                    >
                        <Box>
                            <Typography
                                fontSize={isMobile ? '1.5rem' : '2.125rem'}
                                fontWeight={800}
                                lineHeight={isMobile ? '2rem' : '2.5rem'}
                                sx={{ pt: '1rem', pb: '1.5rem', textAlign: isMobile ? 'center' : 'left' }}
                            >
                                {messages.fundingCalculatorLanding.fundingCalculatorTitleText}
                            </Typography>
                            <Typography
                                fontSize={isMobile ? '0.75rem' : '0.90rem'}
                                fontWeight={500}
                                lineHeight={isMobile ? '1.25rem' : '1.375rem'}
                                sx={{ textAlign: isMobile ? 'center' : 'left' }}
                            >
                                {messages.fundingCalculatorLanding.fundingCalculatorSubTitleText}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            minWidth: isMobile ? '100%' : '50%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: isMobile ? '15rem' : '29.125rem',
                                height: 'auto',
                                mx: 'auto',
                            }}
                        >
                            <img
                                src={headerImage}
                                alt={titles.FUNDING_CALCULATOR}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default CalculatorHeader;

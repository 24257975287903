import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { SideNav } from '../components';
import { RootState } from '../stores/store';
import { convertModulesToOptions } from '../utils/common';
import { SideNavOptions } from 'src/components/SideNav/types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import useUserList from '../hooks/api/useUserList';
import UserAuth from '../services/auth';
import SupersetAuth from '../services/supersetAuth';
import useUserDetails from '../hooks/api/useUserDetails';
import useAuth from '../hooks/useAuth';
import useUserModules from '../hooks/api/useUserModules';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const appData = useSelector((state: RootState) => state?.AppData);
  const userDetails = useSelector((state: RootState) => state?.UserData);
  const modules = useSelector((state: RootState) => state?.ModulesData);
  const { getUserModules } = useUserModules();
  const { getUserList } = useUserList();
  const { getUserDetails } = useUserDetails();
  const { logoutUrl } = useAuth();

  const [sidenavOptions, setSidenavOption] = useState<SideNavOptions[]>([]);

  const token = appData?.auth?.access_token || UserAuth.accessToken || '';

  // Hook to fetch user data for logged in user and also to fetch user management data if user is admin
  useEffect(() => {
    if (token) {
      if (!userDetails?.user?.id) {
        getUserDetails();
      } else if (userDetails?.user?.role === 'ADMIN') {
        getUserList({});
      }
    }
  }, [userDetails, token]);

  // Hook to fetch modules accessible to logged in user
  useEffect(() => {
    const fetchModules = async () => {
      if (modules?.modules?.length === 0 && token) {
        await getUserModules(userDetails?.user?.role === 'ADMIN');
      }
    };

    fetchModules();
  }, [modules, token]);

  // Convert modules to sidenav options dynamically
  useEffect(() => {
    if (modules?.modules) {
      const options = convertModulesToOptions(modules?.modules);
      setSidenavOption(options);
    }
  }, [modules]);

  // Function to clear token and log out user
  const logoutUser = () => {
    SupersetAuth.clearToken();
    UserAuth.clearTokens();
    // to ensure tokens are cleared
    setTimeout(() => {
      window.location.href = logoutUrl;
    }, 1000);
  };

  return (
    <main
      id='main_layout_container'
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        display: 'flex',
        background: theme.palette.custom_background,
      }}
    >
      <SideNav
        options={sidenavOptions}
        isAdmin={userDetails?.user?.role === 'ADMIN'}
        username={userDetails?.user?.username || userDetails?.user?.email || ''}
        onClickLogout={() => logoutUser()}
        customWidth={isMobile ? '70%' : '300px'}
      />
      {isMobile ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            p: 0,
            m: 0,
            mt: theme.sizes.mobile.marginTop,
          }}
        >
          {children ?? <Outlet />}
        </Box>
      ) : (
        <Box
          sx={{
            width: 'calc(100% - 300px)',
            overflow: 'hidden',
            p: 0,
            m: 0,
          }}
        >
          {children ?? <Outlet />}
        </Box>
      )}
    </main>
  );
};

export default MainLayout;

import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme, useMediaQuery, Button, IconButton, Tooltip } from '@mui/material';
import ChartInfoChip from './ChartInfoChip';
import CalculatorChartInput from './CalculatorChartInput';
import FundingCalculatorSummary from './FundingCalculatorSummary';
import { CHART_TAGS } from '../../../constants/constants';
import { LineChart, BarChart } from '../../../components';
import { RootState } from '../../../stores/store';
import { ReactComponent as InfoIconDark } from '../../../assets/icons/info_icon_dark.svg';
import { CalculatorChartData } from '../../../stores/FundingCalculator/types/fundingCalculator';
import { calculateInterpolatedChartData, formatCurrency, percentageDifference } from '../../../utils/common';
import { CurveValues } from '../../../components/Charts/types';
import { genericTexts, labels, messages } from '@utils/translations/en';

interface FundingCalculatorChartBoardPropType {
    toggleLoader: any;
    paybackPeriod: any;
    monthlyExpenditureData: any;
    setMonthlyExpenditureData: any;
};

const FundingCalculatorChartBoard: FC<FundingCalculatorChartBoardPropType> = ({
    toggleLoader,
    paybackPeriod,
    monthlyExpenditureData,
    setMonthlyExpenditureData
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isProfitsCumulative, toggleProfitsCumulative] = useState(true);

    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const isChartLoading = Boolean(toggleLoader && !fundingCalculatorData?.fundingCalculations?.id)

    const getRoasChartData = () => {
        if (monthlyExpenditureData.length) {
            return calculateInterpolatedChartData(monthlyExpenditureData);
        } else if (fundingCalculatorData?.fundingCalculations?.charts?.cumulative_roas?.datasets.length) {
            return fundingCalculatorData.fundingCalculations.charts.cumulative_roas.datasets;
        } else {
            return [];
        }
    };

    const getProfitsChartData = useCallback(() => {
        const data = isProfitsCumulative
            ? fundingCalculatorData?.fundingCalculations?.charts?.cumulative_profit_before_taxes?.datasets
            : fundingCalculatorData?.fundingCalculations?.charts?.profit_before_taxes?.datasets;

        if (data.length) {
            return data;
        } else {
            return [];
        }
    }, [isProfitsCumulative, fundingCalculatorData]);

    const getSurplusFund = () => {
        if (fundingCalculatorData?.fundingCalculations?.summary?.eligibleFund) {
            return formatCurrency(fundingCalculatorData.fundingCalculations.summary.eligibleFund, true);
        }

        return '';
    }

    const getCurrentPaybackPeriod = () => {
        if (fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths) {
            return fundingCalculatorData.fundingCalculations.variables.paybackPeriodInMonths;
        }

        return '';
    }

    const getCurrentRepaymentPeriod = () => {
        if (fundingCalculatorData?.fundingCalculations?.charts?.interest_paid?.datasets?.length) {
            const data = fundingCalculatorData.fundingCalculations.charts.interest_paid.datasets.find((val) => val.label === 'Cash cost')?.data || [];

            if (data.length) {
                const period = data.filter((val) => val.y > 0);
                return period.length;
            }
        }

        return '';
    }

    const getCashBalanceIncrease = () => {
        if (fundingCalculatorData?.fundingCalculations?.charts?.ending_cash_balance?.datasets?.length) {
            const datasets = fundingCalculatorData.fundingCalculations.charts.ending_cash_balance.datasets;
            const investorData = datasets.find(i => i.label === "With PvX Funding")?.data || [];
            const withoutInvestorData = datasets.find(i => i.label === "Without PvX Funding")?.data || [];
            const investmentBalance = investorData[investorData.length - 1].y;
            const nonInvestmentBalance = withoutInvestorData[withoutInvestorData.length - 1].y;

            return Number(Math.round(percentageDifference(investmentBalance, nonInvestmentBalance))).toFixed(0);
        }

        return '';
    }

    return (
        <>
            <Box
                id="calculator_summary_wrapper"
                sx={{
                    width: "100%",
                    height: "max-content",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: "2rem",
                    py: "1.5rem",
                    mb: "1rem",
                }}
            >
                <FundingCalculatorSummary showLoader={toggleLoader} />
                <Typography
                    sx={{
                        mt: '1rem',
                        fontSize: '0.8rem',
                        fontWeight: 400,
                        color: theme.palette.funding_calculator.main_page.font,
                        opacity: 0.6
                    }}
                >
                    <b>{messages.fundingCalculatorLanding.disclaimer}</b><br />{messages.fundingCalculatorLanding.disclaimerMessage}
                </Typography>
            </Box>
            <Box
                id="chart_canvas_1"
                sx={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box sx={{ display: 'flex', alignContent: 'center', mb: "2rem", }} >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 700,
                            lineHeight: "1.5rem",
                            fontFamily: 'work sans'
                        }}
                    >
                        {genericTexts.netCumulativeROAS}
                    </Typography>
                    <Tooltip title={
                        <>
                            <b>{messages.fundingCalculatorLanding.netCumulativeROAS}</b> {messages.fundingCalculatorLanding.netCumulativeROAStooltip}
                        </>
                    }>
                        <IconButton sx={{ px: '0.5rem', pt: '1px', pb: 0 }} >
                            <InfoIconDark style={{ width: '1rem', height: 'auto' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <CalculatorChartInput
                    showSlider={false}
                    paybackPeriod={paybackPeriod}
                    monthlyPaybackValues={monthlyExpenditureData}
                    handleChangeMonthlyPaybackValues={(data: CalculatorChartData) => setMonthlyExpenditureData(data)}
                />
                <Box sx={{ width: "100%", height: '19rem' }} >
                    <LineChart
                        isLoading={isChartLoading}
                        data={getRoasChartData()}
                        type={CHART_TAGS.CUMULATIVE_ROAS}
                    />
                </Box>
            </Box>
            <Box
                id="chart_canvas_2"
                sx={{
                    width: "100%",
                    height: "28.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 700,
                        lineHeight: "1.5rem",
                        mb: "0.5rem",
                        fontFamily: 'work sans'
                    }}
                >
                    {labels.fundingCalculatorPage.monthlyMarketingSpends}
                </Typography>
                {getSurplusFund() && (<ChartInfoChip info={`You can spend ${getSurplusFund()} more over the next ${getCurrentPaybackPeriod()} months`} />)}
                <BarChart
                    isLoading={isChartLoading}
                    data={
                        fundingCalculatorData?.fundingCalculations?.charts
                            ?.monthly_marketing_spends?.datasets || []
                    }
                    type={CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE}
                />
            </Box>
            <Box
                id="chart_canvas_3"
                sx={{
                    width: "100%",
                    height: "25rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 700,
                            lineHeight: "1.5rem",
                            mb: "2rem",
                            fontFamily: 'work sans'
                        }}
                    >
                        {labels.fundingCalculatorPage.netRevenue}
                    </Typography>
                </Box>
                <BarChart
                    isLoading={isChartLoading}
                    data={fundingCalculatorData?.fundingCalculations?.charts?.revenue?.datasets}
                    type={CHART_TAGS.REVENUE}
                />
            </Box>
            <Box
                id="chart_canvas_4"
                sx={{
                    width: "100%",
                    height: "25rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 700,
                            lineHeight: "1.5rem",
                            mb: isMobile ? "1rem" : 0,
                            fontFamily: 'work sans'
                        }}
                    >
                        {isProfitsCumulative ? labels.fundingCalculatorPage.cumulativeProfit : labels.fundingCalculatorPage.profit}
                    </Typography>
                    <Box
                        sx={{
                            width: isMobile ? "100%" : "fit-content",
                            display: "flex",
                            flexDirection: 'row',
                            gap: "0.5rem",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            mb: isMobile ? "1rem" : "0.5rem"
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: "none",
                                width: "8rem",
                                p: isMobile ? '2px' : 'auto',
                                background: isProfitsCumulative ? theme.palette.custom_button.primary : theme.palette.custom_button.secondary,
                                color: isProfitsCumulative ? theme.palette.custom_button.secondary_font : theme.palette.custom_button.primary_font,
                                fontWeight: 600,
                                fontSize: '0.8rem',
                                boxShadow: '0px 2px 6px 2px #00000026',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: isProfitsCumulative ? theme.palette.custom_button.primary : theme.palette.custom_button.secondary,
                                    color: isProfitsCumulative ? theme.palette.custom_button.secondary_font : theme.palette.custom_button.primary_font,
                                },
                            }}
                            onClick={() => toggleProfitsCumulative(true)}
                        >
                            {labels.fundingCalculatorPage.cumulative}
                        </Button>
                        <Button
                            sx={{
                                textTransform: "none",
                                width: "8rem",
                                p: isMobile ? '2px' : 'auto',
                                background: !isProfitsCumulative ? theme.palette.custom_button.primary : theme.palette.custom_button.secondary,
                                color: !isProfitsCumulative ? theme.palette.custom_button.secondary_font : theme.palette.custom_button.primary_font,
                                fontWeight: 600,
                                fontSize: '0.8rem',
                                boxShadow: '0px 2px 6px 2px #00000026',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: !isProfitsCumulative ? theme.palette.custom_button.primary : theme.palette.custom_button.secondary,
                                    color: !isProfitsCumulative ? theme.palette.custom_button.secondary_font : theme.palette.custom_button.primary_font,
                                },
                            }}
                            onClick={() => toggleProfitsCumulative(false)}
                        >
                            {labels.fundingCalculatorPage.monthly}
                        </Button>
                    </Box>
                </Box>
                {isProfitsCumulative ?
                    <LineChart
                        isLoading={isChartLoading}
                        data={getProfitsChartData()}
                        type={CHART_TAGS.PROFIT_BEFORE_TAXES_CUMULATIVE}
                    />
                    : <BarChart
                        isLoading={isChartLoading}
                        data={getProfitsChartData()}
                        type={CHART_TAGS.PROFIT_BEFORE_TAXES}
                    />}
            </Box>
            <Box
                id="chart_canvas_5"
                sx={{
                    width: "100%",
                    height: "28.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 700,
                        lineHeight: "1.5rem",
                        mb: "0.5rem",
                        fontFamily: 'work sans'
                    }}
                >
                    {labels.fundingCalculatorPage.endingCashBalance}
                </Typography>
                <ChartInfoChip info={`Increase in ending cash balance by ${getCashBalanceIncrease()}%`} />
                <LineChart
                    isLoading={isChartLoading}
                    data={
                        fundingCalculatorData?.fundingCalculations?.charts?.ending_cash_balance
                            ?.datasets || []
                    }
                    type={CHART_TAGS.MONTHLY_MARKETING_SPENDS_BALANCE_DELTA}
                />
            </Box>
            <Box
                id="chart_canvas_6"
                sx={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? 0 : "1.5rem",
                    mt: "1rem",
                    mb: isMobile ? "1rem" : "7rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 700,
                        lineHeight: "1.5rem",
                        mb: "0.5rem",
                        fontFamily: 'work sans'
                    }}
                >
                  {labels.fundingCalculatorPage.principalAndInterestRepayment}  
                </Typography>
                <ChartInfoChip info={`Repayment completes in ${getCurrentRepaymentPeriod()} months`} />
                {/* <Box id="interest-paid-doughnut" sx={{ width: "30rem" }}>
                    <DoughnutChart
                        isLoading={isChartLoading}
                        data={
                            fundingCalculatorData?.fundingCalculations?.charts
                                ?.interest_paid.datasets || []
                        }
                        type={CHART_TAGS.INTEREST_PAID_PIE}
                        metadata={{
                            totalInvestmentInterestPaid: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentInterestPaid || 0,
                            totalInvestmentPaybackAmount: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentPaybackAmount || 0,
                        }}
                    />
                </Box> */}
                <Box
                    id="interest-paid-bar-chart"
                    sx={{ height: "20rem", width: "100%" }}
                >
                    <BarChart
                        isLoading={isChartLoading}
                        data={
                            fundingCalculatorData?.fundingCalculations?.charts
                                ?.interest_paid.datasets || []
                        }
                        type={CHART_TAGS.INTEREST_PAID_BAR}
                        metadata={{
                            totalInvestmentInterestPaid: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentInterestPaid || 0,
                            totalInvestmentPaybackAmount: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentPaybackAmount || 0,
                        }}
                    />
                </Box>
            </Box>
            {isMobile && (
                <Box sx={{ height: '4%', width: '100%' }} />
            )}
        </>
    );
}

export default FundingCalculatorChartBoard;

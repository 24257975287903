import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '@mui/material';

interface CalculatorLayoutProps {
    children?: ReactNode;
}

const CalculatorLayout: FC<CalculatorLayoutProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <main
            id='calculator_layout_container'
            style={{
                height: '100vh',
                width: '100vw',
                overflow: 'hidden',
                background: theme.palette.funding_calculator.landing_page.background,
            }}
        >
            {children ?? <Outlet />}
        </main>
    );
};

export default CalculatorLayout;

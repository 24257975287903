import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, useTheme } from '@mui/material';
import loader from '../../assets/images/Loader.json';
import { genericTexts } from '@utils/translations/en';

type FullPageLoaderProps = {
    hasSideNav?: boolean;
};

const FullPageLoader: React.FC<FullPageLoaderProps> = ({ hasSideNav = false }) => {
    const theme = useTheme();
    return (
        <>
            <Box sx={{
                position: 'absolute',
                zIndex: 9999,
                width: '400px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                top: 'calc(50% - 150px)',
                left: 'calc(50% - 100px)',
            }}>
                <Box sx={{ width: '15rem' }}>
                    <Player
                        src={loader}
                        className="player"
                        loop
                        autoplay
                    />
                </Box>
                <p>{genericTexts.pleaseWait}</p>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 9988,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: theme.palette.common.white,
                    opacity: '1'
                }}
            />
        </>
    );
};

export default FullPageLoader;

import { FC, useState, useCallback, useEffect } from 'react';
import { Box, useTheme, useMediaQuery, TextField, Slider, InputAdornment, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as LightningIcon } from '../../../assets/icons/lightning.svg';
import { DEFAULT_PAYBACK_PERIOD_OPTIONS, PAYBACK_PERIOD_OPTIONS, ROAS_PAYBACK_PERIOD_VALUE } from '../../../constants/constants';
import { CalculatorChartData, CalculatorChartDataItem } from '../../../stores/FundingCalculator/types/fundingCalculator';
import { ReactComponent as InfoIconDark } from '../../../assets/icons/info_icon_dark.svg';
import { labels, messages } from '@utils/translations/en';

type CalculatorChartPropType = {
    showSlider?: boolean;
    paybackPeriod: number;
    setPaybackPeriod?: React.Dispatch<React.SetStateAction<number>>;
    monthlyPaybackValues: CalculatorChartData;
    handleChangeMonthlyPaybackValues: (data: CalculatorChartData) => void;
};

const CalculatorChartInput: FC<CalculatorChartPropType> = (props) => {
    const {
        showSlider = false,
        paybackPeriod,
        setPaybackPeriod = (val: number) => null,
        monthlyPaybackValues,
        handleChangeMonthlyPaybackValues,
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [userInputs, setUserInputs] = useState<CalculatorChartData>([]);

    // Hook to update both monthlyPaybackValues and userInputs based on paybackPeriod defaults
    useEffect(() => {
        if (paybackPeriod) {
            const defaultValues = DEFAULT_PAYBACK_PERIOD_OPTIONS[paybackPeriod];
            const data = defaultValues.map((item: CalculatorChartDataItem) => {
                return {
                    month: item.month,
                    value: item.value,
                };
            });

            setUserInputs(data);
            handleChangeMonthlyPaybackValues(data);
        }

    }, [paybackPeriod]);

    // Hook to update monthlyPaybackValues based on userInputs
    useEffect(() => {
        if (userInputs.length > 0) {
            const inputValues = userInputs.filter(input => input.month <= paybackPeriod);
            handleChangeMonthlyPaybackValues(inputValues);
        }
    }, [userInputs]);

    // Hook to load userInputs based on monthlyPaybackValues
    useEffect(() => {
        if (!showSlider && userInputs.length === 0) {
            setUserInputs(monthlyPaybackValues);
        }
    }, [showSlider, monthlyPaybackValues]);

    // Function to record and userInputs
    const handleInputChange = useCallback(
        (month: number, newValue: string) => {
            let value = parseInt(newValue.replace(/[^0-9]/g, ''));
            if (isNaN(value)) {
                value = 0;
            }
            if (value >= 0 && value <= 999) {
                setUserInputs((prevInputs) => {
                    const existingInputIndex = prevInputs.findIndex(input => input.month === month);
                    const updatedInputs = [...prevInputs];
                    if (existingInputIndex >= 0) {
                        updatedInputs[existingInputIndex] = { month, value };
                    } else {
                        updatedInputs.push({ month, value });
                    }
                    return updatedInputs;
                });
            }
        },
        [],
    );

    const getMonthValue = (month: number) => {
        const monthValue = userInputs.find(input => input.month === month)?.value;
        return monthValue !== undefined ? monthValue.toFixed(0) : '';
    };

    return (
        <Box id="calculator_landing_body_chart_inputs">
            {showSlider && (<Box sx={{ display: 'flex', alignContent: 'center', mb: "3rem", }}>
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        lineHeight: "1rem",
                    }}
                >
                    {labels.fundingCalculatorLanding.enterNetCumulativeRoas}
                </Typography>
                <Tooltip title={
                    <>
                        <b>{messages.fundingCalculatorLanding.netCumulativeROAS}</b> {messages.fundingCalculatorLanding.netCumulativeROAStooltip}
                    </>
                }>
                    <IconButton sx={{ px: '0.5rem', pt: '1px', pb: 0 }} >
                        <InfoIconDark style={{ width: '1rem', height: 'auto' }} />
                    </IconButton>
                </Tooltip>
            </Box>)}
            {showSlider ? (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        fontSize: '0.75rem',
                        fontWeight: 500,
                        mb: '2rem'
                    }}
                >
                    <Box sx={{ width: isMobile ? '100%' : 'max-content', pr: '3rem', mb: '2rem', fontSize: '16px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                        <LightningIcon
                            style={{
                                height: '18px',
                                width: '18px',
                                paddingRight: '2px'
                            }}
                        />
                        {messages.fundingCalculatorLanding.paybackPeriodInMonths}
                    </Box>
                    <Box sx={{ width: isMobile ? '100%' : '20rem', px: isMobile ? '3rem' : 'none' }}>
                        <Slider
                            aria-label="Always visible"
                            defaultValue={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === paybackPeriod)}
                            value={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === paybackPeriod)}
                            valueLabelFormat={(index: number) => (`<${PAYBACK_PERIOD_OPTIONS[index].value} Months`)}
                            step={null}
                            marks={PAYBACK_PERIOD_OPTIONS.map((opt, index) => ({ value: index, label: `<${opt.label}` }))}
                            min={0}
                            max={PAYBACK_PERIOD_OPTIONS.length - 1}
                            valueLabelDisplay="on"
                            sx={{
                                '& .MuiSlider-valueLabel': {
                                    backgroundColor: theme.palette.funding_calculator.landing_page.calculator_slider_value_label_background,
                                    color: theme.palette.funding_calculator.landing_page.calculator_slider_value_label_font,
                                    fontSize: '0.75rem',
                                },
                                '& .MuiSlider-markLabel': {
                                    color: theme.palette.funding_calculator.landing_page.calculator_slider_value_label_font,
                                },
                                '& .MuiSlider-track': {
                                    color: theme.palette.funding_calculator.landing_page.calculator_slider_track,
                                },
                                '& .MuiSlider-rail': {
                                    color: theme.palette.funding_calculator.landing_page.calculator_slider_rail,
                                },
                                '& .MuiSlider-thumb': {
                                    color: theme.palette.funding_calculator.landing_page.calculator_slider_thumb,
                                    border: '2px solid white',
                                },
                            }}
                            onChange={(e, index) => setPaybackPeriod(PAYBACK_PERIOD_OPTIONS[index as number].value)}
                        />
                    </Box>
                </Box>
            ) : (
                <></>
            )}
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    mb: '1rem',
                }}
            >
                {ROAS_PAYBACK_PERIOD_VALUE.filter((option) => option.value <= paybackPeriod)
                    .map((option) => (
                        <TextField
                            key={option.value}
                            id={`input-${option.value}`}
                            variant="outlined"
                            sx={{
                                width: '7rem',
                                borderRadius: '8px',
                                mx: '0.5rem',
                                mb: '1rem',
                                '& .MuiInputBase-input': {
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    textAlign: 'center',
                                },
                            }}
                            label={`Month ${option.value}`}
                            type="text"
                            InputProps={{
                                endAdornment: getMonthValue(option.value) ? (
                                    <InputAdornment position="end">
                                        <span style={{ fontSize: '1rem', fontWeight: 500 }}>%</span>
                                    </InputAdornment>
                                ) : null,
                            }}
                            onChange={(e) => handleInputChange(option.value, e.target.value)}
                            value={getMonthValue(option.value)}
                        />
                    ))}
            </Box>
        </Box>
    );
}

export default CalculatorChartInput;

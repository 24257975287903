import { FC } from 'react';
import { useTheme, TextField, SxProps, Theme, TextFieldVariants } from '@mui/material';
import { formatCurrency } from '../../../utils/common';

type CurrencyInputFieldPropType = {
    id?: string;
    label: string;
    variant?: TextFieldVariants;
    sx?: SxProps<Theme>;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error?: boolean;
    disabled?: boolean;
};

const CurrencyInputField: FC<CurrencyInputFieldPropType> = (props) => {
    const {
        id,
        label,
        variant = 'outlined',
        sx,
        value,
        onChange,
        error = false,
        disabled = false
    } = props;
    const theme = useTheme();

    const computedStatusColor = () => {
        if (disabled) {
            return theme.palette.funding_calculator.main_page.font;
        } else if (error) {
            return theme.palette.error.main;
        } else {
            return theme.palette.funding_calculator.main_page.calculator_slider_rail;
        }
    }

    return (
        <TextField
            id={id || label}
            label={label}
            variant={variant}
            InputLabelProps={{ style: { color: error ? theme.palette.error.main : '' } }}
            sx={
                sx ||
                {
                    width: '100%',
                    my: '5px',
                    border: 'none',
                    input: {
                        fontSize: '0.8rem',
                        color: computedStatusColor(),
                        padding: '6px',
                    },
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'transparent',
                        borderColor: computedStatusColor(),
                        '& fieldset': {
                            border: '1px solid',
                            borderRadius: '5px',
                            borderColor: computedStatusColor(),
                        },
                        '&:hover fieldset': {
                            border: '1px solid',
                            borderRadius: '5px',
                            borderColor: computedStatusColor(),
                        },
                        '&.Mui-focused fieldset': {
                            border: '1px solid',
                            borderRadius: '5px',
                            borderColor: computedStatusColor(),
                        },
                    }
                }
            }
            disabled={disabled}
            value={formatCurrency(value)}
            onChange={onChange}
            error={sx ? error : false}
        />
    );
}

export default CurrencyInputField;

import React from "react";
import { Box } from "@mui/material";

interface ChartInfoChipProps {
  info: string;
}

const ChartInfoChip: React.FC<ChartInfoChipProps> = ({ info }) => (
  <Box
    sx={{
      paddingX: "12px",
      paddingY: "8px",
      mb: "2rem",
      background: "linear-gradient(to left top, #59C1E60F, #B3E5FC6B)",
      borderRadius: "5px",
      border: 1,
      borderColor: "#B3E5FC",
      color: "#11111F",
      fontWeight: "600",
      fontSize: "14px",
    }}
  >
    {info}
  </Box>
);

export default ChartInfoChip;

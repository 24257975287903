import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import FilterRow from "./FilterRow";
import { SubmoduleFilterMap } from "../../../stores/Modules/types/modules";
import { genericTexts, titles } from "@utils/translations/en";

type FilterSectionPropType = {
  moduleName: string;
  filterMap: SubmoduleFilterMap | null;
  close?: () => void;
  handleFilterSave: (val: any) => void;
  selectedFilters: string[];
};

const FilterSection: FC<FilterSectionPropType> = (props) => {
  const theme = useTheme();
  const { filterMap, close = () => { }, handleFilterSave, moduleName, selectedFilters } = props;
  const [selectedOptionArray, setSelectedOptionsArray] = useState<any[]>([]);

  const mapSubmoduleFilters = () => {
    if (filterMap) {
      return Object.values(filterMap).map((f, index) => ({
        key: Object.keys(filterMap)[index],
        value: f
      }));
    }
    return [];
  }

  return (
    <Box
      sx={{
        m: 0,
        width: "360px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: "10px",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        background: theme.palette.common.white,
      }}
    >
      <Box sx={{ height: 64, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: 48,
            height: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon onClick={() => close()} sx={{ cursor: "pointer" }} />
        </Box>
        <Typography fontSize={22}>{titles.ADD_FILTERS}</Typography>
      </Box>
      <Typography
        color={theme.palette.custom_menu.font}
        fontSize={20}
        sx={{
          pl: "10px",
          fontWeight: 500
        }}
      >
        {moduleName}
      </Typography>
      <Box sx={{ overflowY: 'auto', paddingBottom: 8 }}>
        {mapSubmoduleFilters()?.map(
          (
            filter,
            index
          ) => (
            <Box
              key={filter.key}
              sx={{
                marginBottom: 1,
                borderRadius: '1rem',
                background: theme.palette.common.white,
              }}
            >
              <Accordion
                key={filter.key}
                defaultExpanded={index === 0}
                sx={{
                  boxShadow: "none",
                  "&::before": {
                    content: '""',
                    backgroundColor: "white",
                  },
                }}
                disableGutters
              >
                <AccordionSummary
                  sx={{
                    color: "#1D1B20",
                    py: "10px",
                    pl: "10px",
                    fontWeight: 500,
                    minHeight: 'unset',
                    height: '100%',
                    '&.Mui-expanded': {
                      minHeight: 'unset',
                      height: '100%',
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                    '&.Mui-expanded .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                  }}

                  expandIcon={<ArrowDropDownIcon />}
                >
                  {filter.key}
                </AccordionSummary>
                <FilterRow
                  filter={{
                    name: filter.key,
                    options: filter.value
                  }}
                  selectedFilters={selectedFilters}
                  setSelectedOptionsArray={setSelectedOptionsArray}
                />
              </Accordion>
              <Box sx={{ height: "1px", backgroundColor: "rgba(0,0,0,0.12)" }} />
            </Box>
          )
        )}
      </Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 20,
          bottom: 0,
          right: 0,
          height: 73,
          width: "360px",
          border: "1px solid #CCC2DC",
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "row-reverse",
          gap: 2,
          background: theme.palette.custom_field_background
        }}
      >
        <Button
          variant="contained"
          sx={{ borderRadius: "100px" }}
          onClick={() => {
            handleFilterSave(selectedOptionArray);
            close();
          }}
        >
          {genericTexts.Save}
        </Button>
        <Button
          variant="outlined"
          onClick={() => close()}
          sx={{
            borderRadius: "100px",
            color: theme.palette.custom_button.primary_font,
            borderColor: theme.palette.custom_button.primary_font,
            '&:hover': {
              borderColor: theme.palette.custom_button.primary_font,
            }
          }}
        >
          {genericTexts.Cancel}
        </Button>
      </Box>
    </Box>
  );
};

export default FilterSection;

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { Box, Typography } from '@mui/material';
import ErrorChart from '../../../assets/images/error-chart.svg';
import LineChartSkelton from './LineChartSkelton';
import { ChartProps, ChartConfigType, LineChartUnitValue } from '../types';
import { getFormattedChartData } from '../utils';
import { getChartConfig } from '../chartConfigs';
import { genericTexts } from '@utils/translations/en';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartAnnotation,
    Filler
);

const LineChart: React.FC<ChartProps> = ({ type, data, metadata, isLoading, error }) => {
    if ((data && !data?.length) || isLoading) return <LineChartSkelton />;

    if (data) {
        const chartData = getFormattedChartData(type, data);
        const chartConfig: ChartConfigType | null = getChartConfig(type, data, metadata);

        if (chartData && chartConfig) {
            return (
                <Box sx={{ height: '100%', width: '100%' }}>
                    <Line
                        data={{
                            labels: chartData.labels,
                            datasets: chartData.datasets as LineChartUnitValue[]
                        }}
                        options={chartConfig.options}
                        plugins={chartConfig.plugins}
                    />
                </Box>
            );
        } else {
            return <LineChartSkelton />;
        }
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={ErrorChart}
                    alt={genericTexts.refreshChart}
                    style={{
                        width: '15rem',
                        height: 'auto',
                        marginBottom: 20
                    }}
                />
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {genericTexts.oopsError}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>
                    {genericTexts.refreshChart}
                </Typography>
            </Box>
        );
    }
};

export default LineChart;
